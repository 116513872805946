<template>
  <main class="patients">
    <LandingHeader />

    <section class="top-screen">
      <div class="top-screen__left">
        <h1 class="top-screen__left__title">{{ $t("landing.patients.top.title") }}</h1>
        <p class="top-screen__left__text">
          {{ $t("landing.patients.top.subtitle") }}
        </p>
        <v-btn class="top-screen__left__btn landing-btn" color="primary" dark depressed @click="gotoLogin"
          >{{ $t("landing.patients.top.logIn") }}
        </v-btn>
      </div>
      <div class="top-screen__right">
        <div class="top-screen__right__img-wrap">
          <img class="top-screen__right__img" src="@/assets/landing/patients-main.webp" />
        </div>
        <img alt="" class="top-screen__right__bg-phone" src="@/assets/landing/patients-main-phone.webp" />
      </div>
      <img alt="" class="top-screen__bg-logo" src="@/assets/landing/bg-logo.svg" />
    </section>

    <section class="telemedicine">
      <h1 class="telemedicine__title">
        {{ $t("landing.patients.telemedicine.title") }}
      </h1>
      <p class="telemedicine__text">{{ $t("landing.patients.telemedicine.text") }}</p>
      <div class="telemedicine__feats">
        <div class="telemedicine__feats__item">
          <img alt="" class="telemedicine__feats__item__img" src="@/assets/landing/feel-better.svg" />
          <p class="telemedicine__feats__item__title">
            {{ $t("landing.patients.telemedicine.feats.feelBetter.title") }}
          </p>
          <p class="telemedicine__feats__item__text">
            {{ $t("landing.patients.telemedicine.feats.feelBetter.text") }}
          </p>
        </div>
        <div class="telemedicine__feats__item">
          <img alt="" class="telemedicine__feats__item__img" src="@/assets/landing/architecture.svg" />
          <p class="telemedicine__feats__item__title">{{ $t("landing.patients.telemedicine.feats.centered.title") }}</p>
          <p class="telemedicine__feats__item__text">{{ $t("landing.patients.telemedicine.feats.centered.text") }}</p>
        </div>
        <div class="telemedicine__feats__item">
          <img alt="" class="telemedicine__feats__item__img" src="@/assets/landing/patients-employer.svg" />
          <p class="telemedicine__feats__item__title">{{ $t("landing.patients.telemedicine.feats.provided.title") }}</p>
          <p class="telemedicine__feats__item__text">{{ $t("landing.patients.telemedicine.feats.provided.text") }}</p>
        </div>
      </div>
    </section>

    <section class="treat">
      <div class="treat__up">
        <div class="treat__up__text-block">
          <h1 class="treat__title">{{ $t("landing.main.treat.title") }}</h1>
          <p class="treat__text">
            {{ $t("landing.main.treat.text") }}
          </p>
        </div>
        <div class="treat__up__controls">
          <div class="treat__up__controls__left" @click="previousTreatSlide"></div>
          <div class="treat__up__controls__right" @click="nextTreatSlide"></div>
        </div>
      </div>
      <div class="treat__carousel-wrap">
        <Flickity ref="flickityTreat1" :options="flickityTreatOptions" class="treat__carousel-wrap__carousel">
          <div class="treat__carousel__item">
            <img alt="" class="treat__carousel__item__img" src="@/assets/landing/covid.svg" />
            <div class="treat__carousel__item__right">
              <p class="treat__carousel__item__text">COVID-19</p>
              <p class="treat__carousel__item__sub-text">{{ $t("landing.main.treat.carousel.covid") }}</p>
            </div>
          </div>
          <div class="treat__carousel__item">
            <img alt="" class="treat__carousel__item__img" src="@/assets/landing/flu.svg" />
            <p class="treat__carousel__item__text">{{ $t("landing.main.treat.carousel.flu") }}</p>
          </div>
          <div class="treat__carousel__item">
            <img alt="" class="treat__carousel__item__img" src="@/assets/landing/cold.svg" />
            <p class="treat__carousel__item__text">{{ $t("landing.main.treat.carousel.commonCold") }}</p>
          </div>

          <div class="treat__carousel__item">
            <img alt="" class="treat__carousel__item__img" src="@/assets/landing/asthma.svg" />
            <p class="treat__carousel__item__text">{{ $t("landing.main.treat.carousel.asthma") }}</p>
          </div>
          <div class="treat__carousel__item">
            <img alt="" class="treat__carousel__item__img" src="@/assets/landing/cough.svg" />
            <p class="treat__carousel__item__text">{{ $t("landing.main.treat.carousel.cough") }}</p>
          </div>
          <div class="treat__carousel__item">
            <img alt="" class="treat__carousel__item__img" src="@/assets/landing/sinus.svg" />
            <p class="treat__carousel__item__text">{{ $t("landing.main.treat.carousel.sinus") }}</p>
          </div>

          <div class="treat__carousel__item">
            <img alt="" class="treat__carousel__item__img" src="@/assets/landing/throat.svg" />
            <p class="treat__carousel__item__text">{{ $t("landing.main.treat.carousel.throat") }}</p>
          </div>
          <div class="treat__carousel__item">
            <img alt="" class="treat__carousel__item__img" src="@/assets/landing/ear.svg" />
            <p class="treat__carousel__item__text">{{ $t("landing.main.treat.carousel.ear") }}</p>
          </div>
          <div class="treat__carousel__item">
            <img alt="" class="treat__carousel__item__img" src="@/assets/landing/pinkeye.svg" />
            <p class="treat__carousel__item__text">{{ $t("landing.main.treat.carousel.pinkeye") }}</p>
          </div>

          <div class="treat__carousel__item">
            <img alt="" class="treat__carousel__item__img" src="@/assets/landing/coldsores.svg" />
            <p class="treat__carousel__item__text">{{ $t("landing.main.treat.carousel.coldSores") }}</p>
          </div>
          <div class="treat__carousel__item">
            <img alt="" class="treat__carousel__item__img" src="@/assets/landing/allergies.svg" />
            <p class="treat__carousel__item__text">{{ $t("landing.main.treat.carousel.allergies") }}</p>
          </div>
          <div class="treat__carousel__item">
            <img alt="" class="treat__carousel__item__img" src="@/assets/landing/rashes.svg" />
            <p class="treat__carousel__item__text">{{ $t("landing.main.treat.carousel.rashes") }}</p>
          </div>
        </Flickity>
        <Flickity ref="flickityTreat2" :options="flickityTreatOptions" class="treat__carousel-wrap__carousel">
          <div class="treat__carousel__item">
            <img alt="" class="treat__carousel__item__img" src="@/assets/landing/acne.svg" />
            <p class="treat__carousel__item__text">{{ $t("landing.main.treat.carousel.acne") }}</p>
          </div>
          <div class="treat__carousel__item">
            <img alt="" class="treat__carousel__item__img" src="@/assets/landing/sprains.svg" />
            <p class="treat__carousel__item__text">{{ $t("landing.main.treat.carousel.sprains") }}</p>
          </div>
          <div class="treat__carousel__item">
            <img alt="" class="treat__carousel__item__img" src="@/assets/landing/nausea.svg" />
            <p class="treat__carousel__item__text">{{ $t("landing.main.treat.carousel.nausea") }}</p>
          </div>

          <div class="treat__carousel__item">
            <img alt="" class="treat__carousel__item__img" src="@/assets/landing/headache.svg" />
            <p class="treat__carousel__item__text">{{ $t("landing.main.treat.carousel.headache") }}</p>
          </div>
          <div class="treat__carousel__item">
            <img alt="" class="treat__carousel__item__img" src="@/assets/landing/urinary.svg" />
            <p class="treat__carousel__item__text">{{ $t("landing.main.treat.carousel.urinary") }}</p>
          </div>
          <div class="treat__carousel__item">
            <img alt="" class="treat__carousel__item__img" src="@/assets/landing/yeast.svg" />
            <p class="treat__carousel__item__text">{{ $t("landing.main.treat.carousel.yeast") }}</p>
          </div>

          <div class="treat__carousel__item">
            <img alt="" class="treat__carousel__item__img" src="@/assets/landing/vaginosis.svg" />
            <p class="treat__carousel__item__text">{{ $t("landing.main.treat.carousel.vaginosis") }}</p>
          </div>
          <div class="treat__carousel__item">
            <img alt="" class="treat__carousel__item__img" src="@/assets/landing/sexual.svg" />
            <p class="treat__carousel__item__text">{{ $t("landing.main.treat.carousel.std") }}</p>
          </div>
          <div class="treat__carousel__item">
            <img alt="" class="treat__carousel__item__img" src="@/assets/landing/meds.svg" />
            <div class="treat__carousel__item__right">
              <p class="treat__carousel__item__text">{{ $t("landing.main.treat.carousel.meds.title") }}</p>
              <p class="treat__carousel__item__sub-text">{{ $t("landing.main.treat.carousel.meds.text") }}</p>
            </div>
          </div>

          <div class="treat__carousel__item">
            <img alt="" class="treat__carousel__item__img" src="@/assets/landing/advise.svg" />
            <p class="treat__carousel__item__text">{{ $t("landing.main.treat.carousel.advise") }}</p>
          </div>
          <div class="treat__carousel__item">
            <img alt="" class="treat__carousel__item__img" src="@/assets/landing/review.svg" />
            <p class="treat__carousel__item__text">{{ $t("landing.main.treat.carousel.review") }}</p>
          </div>
        </Flickity>
      </div>
    </section>

    <section class="steps-section">
      <div class="steps-section__right">
        <h2 class="steps-section__right__title">{{ $t("landing.patients.steps.title") }}</h2>
        <p class="steps-section__right__sub-title">{{ $t("landing.patients.steps.subtitle") }}</p>
        <div class="steps-section__right__steps">
          <div class="steps-section__right__steps__item">
            <span class="steps-section__right__steps__item__number">1</span>
            <div class="steps-section__right__steps__item__text-block">
              <span class="steps-section__right__steps__item__text-block__title">{{
                $t("landing.patients.steps.setup.title")
              }}</span>
              <span class="steps-section__right__steps__item__text-block__text">{{
                $t("landing.patients.steps.setup.text")
              }}</span>
            </div>
          </div>
          <div class="steps-section__right__steps__item">
            <span class="steps-section__right__steps__item__number">2</span>
            <div class="steps-section__right__steps__item__text-block">
              <span class="steps-section__right__steps__item__text-block__title">{{
                $t("landing.patients.steps.basicInfo.title")
              }}</span>
              <span class="steps-section__right__steps__item__text-block__text">{{
                $t("landing.patients.steps.basicInfo.text")
              }}</span>
            </div>
          </div>
          <div class="steps-section__right__steps__item">
            <span class="steps-section__right__steps__item__number">3</span>
            <div class="steps-section__right__steps__item__text-block">
              <span class="steps-section__right__steps__item__text-block__title">{{
                $t("landing.patients.steps.requestVisit.title")
              }}</span>
              <span class="steps-section__right__steps__item__text-block__text">{{
                $t("landing.patients.steps.requestVisit.text")
              }}</span>
            </div>
          </div>
          <div class="steps-section__right__steps__item">
            <span class="steps-section__right__steps__item__number">4</span>
            <div class="steps-section__right__steps__item__text-block">
              <span class="steps-section__right__steps__item__text-block__title">{{
                $t("landing.patients.steps.doctor.title")
              }}</span>
              <span class="steps-section__right__steps__item__text-block__text">{{
                $t("landing.patients.steps.doctor.text")
              }}</span>
            </div>
          </div>
        </div>
        <v-btn class="steps-section__right__btn landing-btn" color="primary" dark depressed @click="gotoLogin"
          >{{ $t("landing.patients.steps.logInNow") }}
        </v-btn>
      </div>
    </section>

    <section class="faq">
      <div class="faq__left">
        <h1 class="faq__left__title">{{ $t("landing.patients.faq.title") }}</h1>
        <p class="faq__left__text">{{ $t("landing.patients.faq.text") }}</p>
        <v-btn class="faq__left__btn landing-btn" color="primary" dark outlined @click="openContactDialog"
          >{{ $t("landing.patients.faq.contactUs") }}
        </v-btn>
      </div>
      <div class="faq__accordion">
        <v-expansion-panels>
          <v-expansion-panel v-for="item in faqItems" :key="item.question" class="faq__accordion__item">
            <v-expansion-panel-header class="faq__accordion__question">{{ item.question }}</v-expansion-panel-header>
            <v-expansion-panel-content class="faq__accordion__answer">
              {{ item.answer }}
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
    </section>

    <section class="feedback">
      <div class="feedback__top">
        <div class="feedback__top__left">
          <img alt="" class="feedback__top__left__img" src="@/assets/landing/heart.svg" />
          <h1 class="feedback__top__left__title">{{ $t("landing.main.feedback.title") }}</h1>
        </div>
        <div class="feedback__top__right">
          <div class="feedback__top__right__left-arrow" @click="previousFeedbackSlide"></div>
          <div class="feedback__top__right__right-arrow" @click="nextFeedbackSlide"></div>
        </div>
      </div>
      <Flickity ref="flickityFeedback" :options="flickityFeedbackOptions" class="feedback__carousel">
        <div class="feedback__slide--wrap">
          <div class="feedback__slide">
            <img alt="" class="feedback__slide__img" src="@/assets/landing/avatar1.png" />
            <div class="feedback__slide__personal-info">
              <p class="feedback__slide__personal-info__name">Brian</p>
              <p class="feedback__slide__personal-info__location">New Orleans LA</p>
            </div>
            <div class="feedback__slide__rating">
              <span class="feedback__slide__rating__number">4.9</span>
              <img alt="" class="feedback__slide__rating__star" src="@/assets/landing/star.svg" />
            </div>
            <p class="feedback__slide__text">
              "I have never experienced such a personalized experienced with a doctor, he listened and suggested to
              follow up with me in a few days for a bad cold. Amazing service."
            </p>
          </div>
        </div>
        <div class="feedback__slide--wrap">
          <div class="feedback__slide">
            <img alt="" class="feedback__slide__img" src="@/assets/landing/avatar2.webp" />
            <div class="feedback__slide__personal-info">
              <p class="feedback__slide__personal-info__name">Kyle</p>
              <p class="feedback__slide__personal-info__location">Long Beach CA</p>
            </div>
            <div class="feedback__slide__rating">
              <span class="feedback__slide__rating__number">4.7</span>
              <img alt="" class="feedback__slide__rating__star" src="@/assets/landing/star.svg" />
            </div>
            <p class="feedback__slide__text">
              "I was out of my blood pressure medications and due to COVID19 and work I was having a hard time making it
              to my PCP, Axis Clinic was able to verify and send a refill of my medication in just a few hours."
            </p>
          </div>
        </div>
        <div class="feedback__slide--wrap">
          <div class="feedback__slide">
            <img alt="" class="feedback__slide__img" src="@/assets/landing/avatar3.webp" />
            <div class="feedback__slide__personal-info">
              <p class="feedback__slide__personal-info__name">Tyler</p>
              <p class="feedback__slide__personal-info__location">San Jose CA</p>
            </div>
            <div class="feedback__slide__rating">
              <span class="feedback__slide__rating__number">5.0</span>
              <img alt="" class="feedback__slide__rating__star" src="@/assets/landing/star.svg" />
            </div>
            <p class="feedback__slide__text">
              "I never thought that speaking to a physician for a medical question could be so simple, I love this
              service."
            </p>
          </div>
        </div>
        <div class="feedback__slide--wrap">
          <div class="feedback__slide">
            <img alt="" class="feedback__slide__img" src="@/assets/landing/avatar4.webp" />
            <div class="feedback__slide__personal-info">
              <p class="feedback__slide__personal-info__name">Lindsey</p>
              <p class="feedback__slide__personal-info__location">Norfolk VA</p>
            </div>
            <div class="feedback__slide__rating">
              <span class="feedback__slide__rating__number">5.0</span>
              <img alt="" class="feedback__slide__rating__star" src="@/assets/landing/star.svg" />
            </div>
            <p class="feedback__slide__text">
              "Having this service for my young children has saved us money, time and worrisome nights! I am truly
              grateful to have this service."
            </p>
          </div>
        </div>

        <div class="feedback__slide--wrap">
          <div class="feedback__slide">
            <img alt="" class="feedback__slide__img" src="@/assets/landing/avatar5.webp" />
            <div class="feedback__slide__personal-info">
              <p class="feedback__slide__personal-info__name">Stephanie</p>
              <p class="feedback__slide__personal-info__location">Memphis TN</p>
            </div>
            <div class="feedback__slide__rating">
              <span class="feedback__slide__rating__number">4.7</span>
              <img alt="" class="feedback__slide__rating__star" src="@/assets/landing/star.svg" />
            </div>
            <p class="feedback__slide__text">
              "Axis.Clinic I can not recommend them enough, great service, they take the time to listen and to explain
              what they are thinking, you speak with the same doctor everytime, really makes you feel better the moment
              you talk to them"
            </p>
          </div>
        </div>
        <div class="feedback__slide--wrap">
          <div class="feedback__slide">
            <img alt="" class="feedback__slide__img" src="@/assets/landing/avatar6.webp" />
            <div class="feedback__slide__personal-info">
              <p class="feedback__slide__personal-info__name">Mayra</p>
              <p class="feedback__slide__personal-info__location">Memphis TN</p>
            </div>
            <div class="feedback__slide__rating">
              <span class="feedback__slide__rating__number">4.8</span>
              <img alt="" class="feedback__slide__rating__star" src="@/assets/landing/star.svg" />
            </div>
            <p class="feedback__slide__text">
              "I was worried about missing work since we have a major project due in just 2 days, I was able to talk to
              my doctor the same day and that night I had my medications for a urinary tract infection"
            </p>
          </div>
        </div>
        <div class="feedback__slide--wrap">
          <div class="feedback__slide">
            <img alt="" class="feedback__slide__img" src="@/assets/landing/avatar7.webp" />
            <div class="feedback__slide__personal-info">
              <p class="feedback__slide__personal-info__name">Katlyn</p>
              <p class="feedback__slide__personal-info__location">Memphis TN</p>
            </div>
            <div class="feedback__slide__rating">
              <span class="feedback__slide__rating__number">5.0</span>
              <img alt="" class="feedback__slide__rating__star" src="@/assets/landing/star.svg" />
            </div>
            <p class="feedback__slide__text">
              "Taking our kids to the doctor's office is always a challenge since my husband and I both work long hours,
              I am so grateful for what they do!, it's a huge relief knowing we have Axis.Clinic to take care of us when
              we need it."
            </p>
          </div>
        </div>
        <div class="feedback__slide--wrap">
          <div class="feedback__slide">
            <img alt="" class="feedback__slide__img" src="@/assets/landing/avatar8.webp" />
            <div class="feedback__slide__personal-info">
              <p class="feedback__slide__personal-info__name">Fernando</p>
              <p class="feedback__slide__personal-info__location">Memphis TN</p>
            </div>
            <div class="feedback__slide__rating">
              <span class="feedback__slide__rating__number">4.9</span>
              <img alt="" class="feedback__slide__rating__star" src="@/assets/landing/star.svg" />
            </div>
            <p class="feedback__slide__text">
              "I am a high risk patients for COVID19 due to my history of ulcerative colitis, I am very worried about
              visiting the ER during the covid pandemic, thanks to Axis.Clinic I have gotten the care I need without
              putting myself at risk."
            </p>
          </div>
        </div>
      </Flickity>
    </section>

    <section class="request">
      <div class="request__text-block">
        <h1 class="request__text-block__title">{{ $t("landing.patients.request") }}</h1>
        <v-btn class="request__text-block__btn landing-btn" color="primary" dark depressed @click="openContactDialog"
          >{{ $t("landing.requestQuote") }}
        </v-btn>
      </div>
      <img alt="" class="request__img" src="@/assets/landing/patients-request.jpg" />
    </section>

    <LandingFooter />

    <ContactUsDialog v-if="contactDialog" @close="closeContactDialog" />

    <img v-if="showArrowUp" alt="" class="arrow-up" src="@/assets/landing/arrow-up.svg" @click="scrollToTop" />
  </main>
</template>

<script>
import Flickity from "vue-flickity";

import ContactUsDialog from "./ContactUsDialog";
import LandingFooter from "./LandingFooter";
import LandingHeader from "./LandingHeader";

export default {
  name: "Patients",
  components: {
    LandingHeader,
    LandingFooter,
    Flickity,
    ContactUsDialog,
  },
  data() {
    return {
      flickityTreatOptions: {
        prevNextButtons: false,
        pageDots: false,
        wrapAround: true,
        autoPlay: true,
      },
      flickityFeedbackOptions: {
        prevNextButtons: false,
        wrapAround: true,
      },
      faqItems: [
        {
          question: this.$t("landing.patients.faq.question1"),
          answer: this.$t("landing.patients.faq.answer1"),
        },
        {
          question: this.$t("landing.patients.faq.question2"),
          answer: this.$t("landing.patients.faq.answer2"),
        },
        {
          question: this.$t("landing.patients.faq.question3"),
          answer: this.$t("landing.patients.faq.answer3"),
        },
        {
          question: this.$t("landing.patients.faq.question4"),
          answer:
            " Yes, we are able to prescribe a wide range of medications tradionally given by your primary care physician, urgent care or emergency department. We do not prescribe or refill any type of controlled substances such as narcotics or lifestyle medications (eg., weight loss, erectile disfunction). ",
        },
        {
          question: this.$t("landing.patients.faq.question5"),
          answer: this.$t("landing.patients.faq.answer5"),
        },
        {
          question: this.$t("landing.patients.faq.question6"),
          answer: this.$t("landing.patients.faq.answer6"),
        },
        {
          question: this.$t("landing.patients.faq.question7"),
          answer: this.$t("landing.patients.faq.answer7"),
        },
        {
          question: this.$t("landing.patients.faq.question8"),
          answer: this.$t("landing.patients.faq.answer8"),
        },
        {
          question: this.$t("landing.patients.faq.question9"),
          answer: this.$t("landing.patients.faq.answer9"),
        },
      ],
      contactDialog: false,
      showArrowUp: false,
    };
  },
  methods: {
    nextTreatSlide() {
      this.$refs.flickityTreat1.next();
      this.$refs.flickityTreat2.next();
    },
    previousTreatSlide() {
      this.$refs.flickityTreat1.previous();
      this.$refs.flickityTreat2.previous();
    },
    nextFeedbackSlide() {
      this.$refs.flickityFeedback.next();
    },
    previousFeedbackSlide() {
      this.$refs.flickityFeedback.previous();
    },
    openContactDialog() {
      this.contactDialog = true;
    },
    closeContactDialog() {
      this.contactDialog = false;
    },
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: "auto" });
    },
    onScroll() {
      this.showArrowUp = window.scrollY > 300;
    },
    gotoLogin() {
      this.$router.push("/auth/signin");
    },
  },
  mounted() {
    window.scrollTo({ top: 0, behavior: "auto" });
    window.addEventListener("scroll", this.onScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll);
  },
};
</script>

<style lang="scss">
.patients {
  overflow-x: hidden;

  * {
    font-size: 18px;
  }

  .arrow-up {
    position: fixed;
    bottom: 50px;
    right: 50px;
    cursor: pointer;
    width: 60px;
    height: 60px;
    opacity: 0.5;
    z-index: 9;

    @media (max-width: 768px) {
      width: 50px;
      height: 50px;
    }

    &:hover {
      opacity: 1;
    }
  }

  .flickity-page-dots {
    text-align: left;
    padding-left: 0 !important;

    @media (max-width: 480px) {
      margin-left: 40px;
    }
  }

  .flickity-page-dots .dot {
    width: 15px;
    height: 15px;
    transition: 0.3s ease;
  }

  .flickity-page-dots .dot.is-selected {
    background: $primary;
    width: 30px;
    border-radius: 15px;
  }

  .top-screen {
    background-color: $primarylight1;
    display: flex;
    justify-content: space-between;
    padding: 150px 0 50px;
    position: relative;

    @media (max-width: 1366px) {
      padding: 100px 0 50px;
    }
    @media (max-width: 768px) {
      padding: 60px 0 50px;
    }

    &:before {
      content: "";
      position: absolute;
      bottom: 100%;
      left: 0;
      width: 100%;
      height: 200px;
      background-color: $primarylight1;
      z-index: 2;
    }

    &__bg-logo {
      position: absolute;
      top: 50px;
      left: 50%;
      transform: translateX(-50%);
      width: 370px;
      height: 370px;

      @media (max-width: 768px) {
        width: 250px;
        height: 250px;
      }
    }

    &__right {
      position: relative;
      width: 50%;
      z-index: 1;
      margin-left: 100px;
      display: flex;
      align-items: flex-end;

      @media (max-width: 1366px) {
        margin-left: 40px;
      }
      @media (max-width: 1180px) {
        align-items: center;
      }
      @media (max-width: 768px) {
        display: none;
      }

      &__img {
        width: 100%;
        position: absolute;
        left: 0;
        bottom: -97px;

        @media (max-width: 1024px) {
          bottom: -68px;
        }
      }

      &__img-wrap {
        overflow: hidden;
        position: absolute;
        width: 180%;
        height: 200%;
        left: 0;
        max-width: 966px;
        bottom: -50px;
      }

      &__bg-phone {
        position: absolute;
        bottom: -160px;
        left: -125px;
        z-index: 2;
        width: 45%;

        @media (max-width: 1180px) {
          bottom: -130px;
          left: -80px;
        }
        @media (max-width: 768px) {
          display: none;
        }
      }
    }

    &__left {
      margin: 0 40px;
      width: 65%;

      @media (max-width: 1024px) {
        margin: 30px;
      }
      @media (max-width: 768px) {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      &__title {
        font-size: 66px;
        line-height: 76px;
        max-width: 700px;
        position: relative;
        z-index: 2;

        @media (max-width: 1024px) {
          font-size: 46px;
          line-height: 54px;
        }

        @media (max-width: 768px) {
          font-size: 40px;
          line-height: 45px;
          text-align: center;
        }
        @media (max-width: 480px) {
          font-size: 32px;
          line-height: 35px;
        }

        strong {
          font-size: 66px;
          position: relative;
          z-index: 2;

          @media (max-width: 1024px) {
            font-size: 52px;
            line-height: 60px;
          }
          @media (max-width: 768px) {
            font-size: 40px;
            line-height: 45px;
          }
          @media (max-width: 480px) {
            font-size: 32px;
            line-height: 35px;
          }
        }
      }

      &__text {
        margin: 18px 0 32px;
        max-width: 550px;
        position: relative;
        z-index: 2;
        @include tablet {
          text-align: center;
        }
      }

      &__btn {
        margin-bottom: 50px;
      }
    }
  }

  .telemedicine {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 150px 0 140px;

    @media (max-width: 1024px) {
      padding: 100px 0;
    }
    @media (max-width: 768px) {
      padding: 60px 20px;
    }

    &__title {
      font-size: 52px;
      line-height: 62px;
      text-align: center;
      letter-spacing: -0.827586px;
      max-width: 900px;

      @media (max-width: 768px) {
        font-size: 40px;
      }
      @media (max-width: 480px) {
        font-size: 28px;
        line-height: 1;
      }
    }

    &__text {
      margin: 20px 0 40px;
      @include tablet {
        text-align: center;
      }
    }

    &__feats {
      display: flex;
      max-width: 1100px;
      width: 100%;
      flex-wrap: wrap;
      justify-content: space-between;

      @media (max-width: 1024px) {
        flex-direction: column;
        align-items: center;
      }

      &__item {
        display: flex;
        flex-direction: column;
        max-width: 330px;
        align-items: center;

        @media (max-width: 1024px) {
          margin-bottom: 20px;
        }

        &__text {
          text-align: center;
        }

        &__title {
          margin: 16px 0 10px;
          font-weight: bold;
          font-size: 24px;

          @media (max-width: 768px) {
            font-size: 20px;
          }
        }

        &__img {
          height: 44px;
        }
      }
    }
  }

  .treat {
    padding: 100px 0 250px;
    background-color: $primary;

    @media (max-width: 1024px) {
      padding: 100px 0;
    }
    @media (max-width: 768px) {
      padding: 60px 0;
    }

    &__carousel-wrap {
      &__carousel {
        & > .flickity-viewport {
          overflow: visible;
        }

        &:last-child {
          margin-top: 20px;
          transform: translateX(-150px);
        }
      }
    }

    &__up {
      display: flex;
      justify-content: space-between;
      padding: 0 40px;
      align-items: center;
      margin-bottom: 50px;

      @media (max-width: 480px) {
        flex-direction: column;
      }

      &__controls {
        display: flex;
        margin-left: 30px;
        align-self: flex-end;

        &__left {
          width: 53px;
          height: 53px;
          border-radius: 50%;
          border: 2px solid #fff;
          position: relative;
          margin-right: 20px;
          cursor: pointer;
          transition: 0.2s ease;

          &:before {
            content: "";
            position: absolute;
            left: 50%;
            top: calc(50% - 4px);
            transform: translate(-50%) rotate(-45deg);
            width: 10px;
            height: 2px;
            background-color: #fff;
            transition: 0.2s ease;
          }

          &:after {
            content: "";
            position: absolute;
            left: 50%;
            bottom: calc(50% - 4px);
            transform: translate(-50%) rotate(45deg);
            width: 10px;
            height: 2px;
            background-color: #fff;
            transition: 0.2s ease;
          }

          &:hover {
            background-color: #fff;

            &:before,
            &:after {
              background-color: $primary;
            }
          }
        }

        &__right {
          width: 53px;
          height: 53px;
          border-radius: 50%;
          border: 2px solid #fff;
          position: relative;
          margin-right: 20px;
          background-color: #fff;
          cursor: pointer;
          opacity: 0.7;
          transition: 0.2s ease;

          &:before {
            content: "";
            position: absolute;
            left: 50%;
            top: calc(50% - 4px);
            transform: translate(-50%) rotate(45deg);
            width: 10px;
            height: 2px;
            background-color: $primary;
            transition: 0.2s ease;
          }

          &:after {
            content: "";
            position: absolute;
            left: 50%;
            bottom: calc(50% - 4px);
            transform: translate(-50%) rotate(-45deg);
            width: 10px;
            height: 2px;
            background-color: $primary;
            transition: 0.2s ease;
          }

          &:hover {
            opacity: 1;
          }
        }
      }
    }

    &__title {
      color: #fff;
      font-size: 52px;
      line-height: 62px;
      letter-spacing: -1px;
      max-width: 480px;

      @media (max-width: 1024px) {
        font-size: 40px;
        line-height: 50px;
      }
      @media (max-width: 768px) {
        font-size: 28px;
        line-height: 1;
      }
    }

    &__text {
      color: #fff;
      max-width: 550px;
      margin-top: 20px;

      @media (max-width: 550px) {
        font-size: 14px;
      }
    }

    &__carousel {
      &__item {
        background-color: #fff;
        border-radius: 6px;
        width: 276px;
        height: 170px;
        margin-right: 20px;
        padding: 30px 22px;
        display: grid;
        grid-template-columns: 1fr 4fr;
        column-gap: 12px;

        @media (max-width: 768px) {
          width: 240px;
          height: 145px;
          padding: 20px 30px;
        }
        @media (max-width: 480px) {
          width: 170px;
          height: 150px;
          padding: 20px;
          justify-content: center;
          align-items: center;
        }

        &__img {
          margin-bottom: 20px;
          height: 50px;

          @media (max-width: 480px) {
            height: 35px;
            margin-bottom: 10px;
          }
        }

        &__text {
          font-size: 24px;
          font-weight: bold;
          margin-bottom: 0;
          line-height: 29px;

          @media (max-width: 1024px) {
            font-size: 18px;
          }
          @media (max-width: 768px) {
            line-height: 24px;
          }
          @media (max-width: 480px) {
            font-size: 14px;
            text-align: center;
          }
        }

        &__sub-text {
          @media (max-width: 1024px) {
            font-size: 14px;
          }
          @media (max-width: 480px) {
            text-align: center;
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .steps-section {
    display: flex;
    background-image: url("../../assets/landing/patients-steps.png");
    padding: 150px 0;
    background-repeat: no-repeat;
    background-size: calc(50% + 80px);
    background-position: left;
    background-position-x: -20%;
    @include wideUp {
      background-position-x: 0;
      background-size: contain;
    }
    @include extraLarge {
      background-size: calc(50% + 80px);
    }

    @include desktop {
      background-size: calc(40% + 80px);
      padding: 100px 0;
    }
    @include tablet {
      background-image: none;
    }
    @include mobile {
      padding: 60px 0;
    }

    &__right {
      display: flex;
      flex-direction: column;
      margin-right: 40px;
      align-items: flex-start;
      margin-left: calc(50% + 80px);
      @include desktop {
        margin-left: calc(40% + 40px);
      }
      @include tablet {
        align-items: center;
        margin-left: 40px;
      }

      &__title {
        font-size: 52px;
        line-height: 62px;
        letter-spacing: -0.827586px;

        @media (max-width: 1024px) {
          font-size: 40px;
          line-height: 50px;
          text-align: center;
        }
        @media (max-width: 768px) {
          font-size: 28px;
          line-height: 1;
        }
      }

      &__sub-title {
        margin: 20px 0 40px;
      }

      &__steps {
        margin-bottom: 40px;

        &__item {
          display: flex;
          align-items: center;

          &:not(:last-child) {
            margin-bottom: 25px;
          }

          &__number {
            font-size: 66px;
            margin-right: 34px;
            color: $primary;
            font-weight: bold;

            @media (max-width: 1024px) {
              font-size: 58px;
            }
            @media (max-width: 768px) {
              font-size: 50px;
            }
            @media (max-width: 580px) {
              font-size: 40px;
              margin-right: 20px;
            }
          }

          &__text-block {
            display: flex;
            flex-direction: column;

            &__title {
              font-weight: bold;
              font-size: 24px;

              @media (max-width: 580px) {
                font-size: 18px;
              }
            }

            &__text {
              @media (max-width: 580px) {
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  }

  .feedback {
    padding: 100px 0 140px;
    position: relative;

    @media (max-width: 480px) {
      padding: 70px 0;
    }

    &__carousel {
      padding: 20px 40px;

      @media (max-width: 480px) {
        padding: 20px 0 70px;
      }
    }

    &__top {
      padding: 0 40px;
      display: flex;
      align-items: flex-end;
      justify-content: space-between;

      @media (max-width: 768px) {
        flex-direction: column;
        justify-content: flex-start;
      }

      &__left {
        &__title {
          font-size: 52px;
          line-height: 62px;
          letter-spacing: -1px;
          margin-top: 35px;
          max-width: 580px;

          @media (max-width: 1150px) {
            font-size: 40px;
          }
          @media (max-width: 768px) {
            margin-bottom: 30px;
          }
          @media (max-width: 480px) {
            font-size: 28px;
            line-height: 1;
          }
        }

        &__img {
          @media (max-width: 1150px) {
            width: 60px;
            height: 60px;
          }
          @media (max-width: 480px) {
            width: 40px;
            height: 40px;
          }
        }
      }

      &__right {
        display: flex;
        z-index: 4;
        @media (max-width: 768px) {
          align-self: flex-end;
        }
        @media (max-width: 480px) {
          position: absolute;
          bottom: 80px;
        }

        &__left-arrow {
          width: 53px;
          height: 53px;
          border-radius: 50%;
          border: 2px solid $primary;
          position: relative;
          margin-right: 20px;
          cursor: pointer;
          transition: 0.2s ease;

          &:before {
            content: "";
            position: absolute;
            left: 50%;
            top: calc(50% - 4px);
            transform: translate(-50%) rotate(-45deg);
            width: 10px;
            height: 2px;
            background-color: $primary;
            transition: 0.2s ease;
          }

          &:after {
            content: "";
            position: absolute;
            left: 50%;
            bottom: calc(50% - 4px);
            transform: translate(-50%) rotate(45deg);
            width: 10px;
            height: 2px;
            background-color: $primary;
            transition: 0.2s ease;
          }

          &:hover {
            background-color: $primary;

            &:before,
            &:after {
              background-color: #fff;
            }
          }
        }

        &__right-arrow {
          width: 53px;
          height: 53px;
          border-radius: 50%;
          border: 2px solid $primary;
          position: relative;
          margin-right: 20px;
          background-color: $primary;
          cursor: pointer;
          opacity: 0.8;
          transition: 0.2s ease;

          &:before {
            content: "";
            position: absolute;
            left: 50%;
            top: calc(50% - 4px);
            transform: translate(-50%) rotate(45deg);
            width: 10px;
            height: 2px;
            background-color: #fff;
            transition: 0.2s ease;
          }

          &:after {
            content: "";
            position: absolute;
            left: 50%;
            bottom: calc(50% - 4px);
            transform: translate(-50%) rotate(-45deg);
            width: 10px;
            height: 2px;
            background-color: #fff;
            transition: 0.2s ease;
          }

          &:hover {
            opacity: 1;
          }
        }
      }
    }

    &__slide {
      width: 430px;
      max-height: 230px;
      display: grid;
      grid-template-columns: 50px 1fr 50px;
      grid-template-rows: 1fr 1fr;
      padding: 30px;
      box-shadow: 0px 2px 26px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      margin: 0 16px;
      align-items: center;
      row-gap: 16px;

      @media (max-width: 480px) {
        width: 360px;
      }

      &--wrap {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 270px;
      }

      &__img {
        height: 50px;
        width: 50px;
        border-radius: 50%;
      }

      &__personal-info {
        margin-left: 20px;

        &__name {
          margin-bottom: 0 !important;
          font-weight: bold;
        }

        &__location {
          margin-bottom: 0 !important;
          font-size: 14px;
        }
      }

      &__rating {
        display: flex;
        justify-content: space-between;
      }

      &__text {
        grid-column: 1 / end;
        font-size: 16px;

        @media (max-width: 480px) {
          font-size: 14px;
        }
      }
    }
  }

  .request {
    display: flex;
    background-color: $primarylight1;

    &__img {
      width: 50%;
      @media (max-width: 580px) {
        display: none;
      }
    }

    &__text-block {
      margin: 0 40px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      width: 50%;

      @media (max-width: 900px) {
        margin: 40px;
      }
      @media (max-width: 580px) {
        width: 100%;
        align-items: center;
      }

      &__title {
        font-size: 52px;
        line-height: 62px;
        letter-spacing: -1px;
        margin-bottom: 50px;

        @media (max-width: 1150px) {
          font-size: 40px;
          line-height: 1;
        }
        @media (max-width: 768px) {
          font-size: 28px;
        }
        @media (max-width: 580px) {
          text-align: center;
          margin-bottom: 30px;
        }
        @media (max-width: 380px) {
          font-size: 24px;
        }
      }
    }
  }

  .faq {
    padding: 110px 40px;
    background-color: #f6f6f6;
    display: flex;
    position: relative;

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;
      padding: 50px 40px 210px;
    }

    &__left {
      margin-right: 10%;

      &__title {
        font-size: 52px;
        line-height: 62px;
        letter-spacing: -1px;
        max-width: 320px;
        margin-bottom: 140px;

        @media (max-width: 1024px) {
          font-size: 40px;
          line-height: 50px;
          margin-bottom: 100px;
        }
        @media (max-width: 768px) {
          font-size: 32px;
          margin-bottom: 30px;
          max-width: 100%;
        }
        @media (max-width: 580px) {
          font-size: 24px;
          text-align: center;
          line-height: 1;
        }
      }

      &__text {
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 24px;
        max-width: 250px;

        @media (max-width: 768px) {
          font-size: 18px;
          position: absolute;
          bottom: 100px;
          left: 50%;
          transform: translateX(-50%);
          max-width: 100%;
          width: 100%;
          text-align: center;
        }
      }

      &__btn {
        @media (max-width: 768px) {
          position: absolute;
          bottom: 40px;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }

    &__accordion {
      max-width: 670px;

      &__item {
        background-color: transparent !important;
        border-bottom: 1px solid $primaryblack2;

        &:before,
        &:after {
          display: none;
        }
      }

      &__question {
        font-size: 24px;
        font-weight: bold;
        padding: 24px;

        @media (max-width: 1024px) {
          font-size: 18px;
        }
        @media (max-width: 580px) {
          font-size: 14px;
          padding: 16px 24px;
        }
      }

      &__answer {
        @media (max-width: 580px) {
          div {
            font-size: 14px;
          }
        }
      }
    }
  }
}
</style>
